import { useRef } from 'react'
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import 'Customer/Styles/MainStyles/holidaypackages.css'
import { image_url } from 'Utils/BaseURL';
import { useNavigate , Link} from 'react-router-dom';

function MostPopularResort({allHotelsList}) {
    const navigation =useNavigate()
    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false
    };

    const nextArrow = () => {
        sliderRef.slickNext();
    };
    const previousArrow = () => {
        sliderRef.slickPrev();
    };
    return (
        <>
            <section className="holiday_packages_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 position-relative">
                            <div className="holiday_packages_wrapper_sec_heading">
                                <h2>Most Popular Resorts</h2>
                                <div className="d-flex align-items-center">
                                    <IoIosArrowBack onClick={() => previousArrow()} />
                                    <IoIosArrowForward onClick={() => nextArrow()} />
                                </div>
                            </div>

                            <div className="slider-container ">
                                <Slider
                                    ref={slider => {
                                        sliderRef = slider;
                                    }}
                                    {...settings}>
                                    {
                                        allHotelsList?.map((item, i) => {
                                            return <div key={i} className="holiday_package_carousel_card_main position-relative"
                                            onClick={()=>navigation(`/customer/resort-details/${item?._id}`)}
                                            >
                                                {
                                                    item?.roomImage?.length>0
                                                    ?
                                                    <img src={`${image_url}${item?.roomImage[0]?.src}`} alt="" />:
                                                    null
                                                }
                                               
                                                <div className='holiday_package_carousel_card_main_text' >
                                                    <h4>{item?.hotelName}</h4>
                                                    <h6>{item?.hotelTitle?.slice(0,20)}</h6>
                                                    <h6>Starting at {item?.price} USD
                                                        per person</h6>
                                                </div>
                                            </div>
                                        })
                                    }

                                </Slider>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MostPopularResort
