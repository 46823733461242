import { useState } from "react";
import "Customer/Styles/MainStyles/resortDetails/resortphotogallery.css";
import { image_url } from "Utils/BaseURL";
import { FaLocationDot } from "react-icons/fa6";


function ResortPhotoGallery({ singleHotelData, allOfferLists }) {
  const data = [1, 2, 3];
  return (
    <div>
      <section className="detail_page_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="detail_gallery_wrapper">
                <div className="row">
                  <div className="col-xl-8 col-12">
                    <h4>Photo Gallery</h4>
                    <div className="main_gallery">
                      <div className="row">
                        <div className="col-8">
                          <div className="gallery_display_img">
                            <img
                              src={`${image_url}${singleHotelData?.roomImage[0]?.src}`}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="side_gallery">
                            <div className="side_gallery_img">
                              <img
                                src={`${image_url}${singleHotelData?.roomImage[1]?.src}`}
                                alt=""
                              />
                            </div>
                            <div className="side_gallery_img">
                              <div className="card text-white">
                                <img
                                  src={`${image_url}${singleHotelData?.roomImage[2]?.src}`}
                                  alt="img"
                                />
                                <div className="card-img-overlay">
                                  <button className="btn">
                                    <a href="#">View All</a>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="hotel_titles">
                         <h2>{singleHotelData?.hotelName}</h2>
                         <h6>{singleHotelData?.hotelTitle}</h6>
                         <p>{singleHotelData?.description}</p>
                         <div className="d-flex align-items-center" ><FaLocationDot /> <span className="mx-2" >{singleHotelData?.address}</span></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-12 d-xxl-block d-none ">
                    <div className="detail_flatoff_wrapper">
                      <div className="row">
                        <div className="col-xl-12 col-lg-4 col-12">
                          <h4>Offers</h4>
                          {allOfferLists?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="package_flatoff_cards mb-2"
                              >
                                <div className="row">
                                  <div className="col-4 d-flex align-items-center justify-content-center ">
                                    <div className="flatoff_img">
                                      <img
                                        src={`${image_url}${item?.image}`}
                                        alt="img"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-8">
                                    <div className="flatoff_content_wrapper">
                                      <span>T&C Apply</span>
                                      <h4>{item?.offerName}</h4>
                                      <h2>{item?.offerType}</h2>
                                      <p>
                                        {item?.description}
                                      </p>
                                      <h2>{item?.price}</h2>
                                      <div className="flatoff_btn">
                                        <button className="btn">
                                          <a href="#">Book Now</a>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResortPhotoGallery;
