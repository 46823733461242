import React, { useState, useEffect } from "react";
import { getAllOffersApi } from "Customer/Apis/MainApis/mainApis";
import { image_url } from "Utils/BaseURL";

import HomeBanner from "Customer/Layouts/HomeLayouts/HomeBanner";
import Footer from "Customer/Components/Footer";
import { useNavigate } from "react-router-dom";

function AllOffersListing() {
  const navigation = useNavigate();
  const [allOffersList, setAllOffersList] = useState([]);

  useEffect(() => {
    getAllOffers();
  }, []);
  const getAllOffers = async () => {
    getAllOffersApi(1, "", (data) => {
      setAllOffersList(data?.data);
    });
  };

  return (
    <div>
      <HomeBanner />
      <div id="all" className="content active my-5 mx-4">
        <h4>All offers</h4>
        <div className="row">
          {allOffersList?.length === 0 ? (
            <div>
              <h6>No Offers</h6>
            </div>
          ) : (
            ""
          )}
          {allOffersList?.map((item, i) => {
            return (
              <div key={i} className="col-xl-4 col-lg-6 col-md-6 col-12">
                <div className="offer_tabs_cards">
                  <div className="row">
                    <div className="col-4">
                      <div className="offers_tabs_card_img">
                        <img src={`${image_url}${item?.image}`} alt="image" />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="offer_card_content">
                        <div className="offer_card_heading d-flex justify-content-between">
                          <span className="offer_card_hotel_type" >{item?.offerFor}</span>
                          <span>T&C Apply</span>
                        </div>

                        <div className="offer_card_heading">
                          <h4>{item?.offerName}</h4>
                        </div>
                        <div className="offer_card_content">
                          <p>{item?.description} </p>
                        </div>
                        <div className="offer_card_content_discount">
                          <p>{item?.discountInPercentage}% Off </p>
                        </div>
                        {/* discountInPercentage */}
                        <div className="offer_card_btn">
                          <button
                            onClick={() => {
                              if (item?.offerFor === "Package") {
                                navigation(
                                  `/view-all-offers/packages/${item?._id}`
                                );
                              } else if (item?.offerFor === "Hotel") {
                                navigation(
                                  `/view-all-offers/hotels/${item?._id}`
                                );
                              } else {
                                navigation(
                                  `/view-all-offers/jungle-safari/${item?._id}`
                                );
                              }
                            }}
                            className="btn"
                          >
                            Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllOffersListing;
