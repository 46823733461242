import React,{useState, useEffect} from 'react'
import JungleSafariFilters from 'Customer/Layouts/JungleSafariListingLayouts/JungleSafariFilters'
import JungleSafariListingLayout from 'Customer/Layouts/JungleSafariListingLayouts/JungleSafariListingLayout'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import { getAlljungleSafariApi } from 'Customer/Apis/MainApis/mainApis'

function JungleSafariListing() {
  const [allPackageListing, setAllPackagesListing] = useState([])
  const [page, setPage] = useState(1)

  useEffect(()=>{
      getAllPackages()
  },[])

  const getAllPackages = async()=>{
    getAlljungleSafariApi(page,(data)=>{
      setAllPackagesListing(data)
    })
  }


  return (
    <div>
      <HomeBanner />

      <section className="mx-xxl-5 px-xxl-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            < div className="col-12">
              <div className="">
                
                <div className="row">
                  <div className="col-xxl-2 col-lg-3 col-sm-3">
                    <JungleSafariFilters />
                  </div>
                  <div className='col-xxl-10 col-lg-9 col-sm-9' >
                    <JungleSafariListingLayout allPackageListing={allPackageListing} offer={false} offerId={false} offerDiscountPrice={false} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default JungleSafariListing
