import React,{useState, useEffect} from 'react'
import ResortFilters from 'Customer/Layouts/ResortListingLayouts/ResortFilters'
import ResortListingLayout from 'Customer/Layouts/ResortListingLayouts/ResortListingLayout'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import { getAllListingOfferApi } from 'Customer/Apis/MainApis/mainApis'

function ResortOfferListing() {
  let offerDiscountPrice = window.location.pathname.split("/")[4];
  const [allHotelsListing, setAllHotelsListing] = useState([])
  const [page, setPage] = useState(1)

  useEffect(()=>{
      getAllHotels()
  },[])

  const getAllHotels = async()=>{
    let offerId = window.location.pathname.split("/")[3];
    getAllListingOfferApi(page,offerId,offerDiscountPrice,(data)=>{
      setAllHotelsListing(data)
    })
  }


  return (
    <div>
      <HomeBanner />

      <section className="mx-0 px-0 mx-xxl-5 px-xxl-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            < div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-2">
                    <ResortFilters />
                  </div>
                  <div className='col-10' >
                    <ResortListingLayout allHotelsListing={allHotelsListing} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResortOfferListing
